<template>
	<div v-loading="loading">
		<el-upload class="upload-demo" :auto-upload="auto_upload" :action="uploadUrl" ref="upload" :on-change="fnOnChange"
			:before-upload="fnBeforeUpload" :on-success="fnUploadSuccess" :on-exceed="fnUploadExceed" :on-remove="fnOnRemove"
			:data="data_extra" :headers="uploadHeaders" :http-request="fnUploadRequest" :show-file-list="showFileList"
			:file-list="fileList" :limit="limit" :accept="accept" :list-type="listType" multiple drag>
			<!-- <i class="el-icon-upload"></i> -->
			<!-- <div class="el-upload__text">{{$t('i18nn_1ecc8be9ba1c940a')}}</em></div> -->
			<!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" width="148" height="148"> -->
			<!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
			<!-- <el-button slot="trigger" size="small" type="primary" icon="el-icon-zoom-in"> -->
			<!-- 选取文件 -->
			<!-- {{ $t('module.upload.Choose_File') }}、 -->
			<!-- {{btnText?btnText:$t('module.upload.Choose_File')}} -->
			<!-- </el-button> -->

			<div slot="trigger">
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					<span>{{$t('i18nn_3f6074f3db84a466')}}</span>,
					<span>{{$t('i18nn_59bcca694e60fcfd')}}</span>
					<em>{{btnText?btnText:$t('module.upload.Choose_File')}}</em>

				</div>

				<!-- <el-button size="small" type="primary" icon="el-icon-zoom-in"> -->
				<!-- 选取文件 -->
				<!-- {{ $t('module.upload.Choose_File') }}、 -->
				<!-- {{btnText}} -->
				<!-- </el-button> -->
			</div>

			<!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload" icon="el-icon-upload2"> -->
			<!-- 上传到服务器 -->
			<!-- {{ $t('module.upload.Upload_server') }} -->
			<!-- </el-button> -->
			<div class="el-upload__tip">
				<!-- 上传文件大小不能超过 -->
				<!-- 注意： -->
				<!-- <br /> -->
				<!-- 1、{{$t('module.upload.uploaded_over')}} {{maxSizeMB}}M<br /> -->
				<span>{{$t('module.upload.upload_el_wh_1')}}</span>：
				<span>{{$t('module.upload.upload_el_wh_2')}}</span>
				{{ limit }}
				<span>{{$t('module.upload.upload_el_wh_3')}}</span>，
				<span>{{$t('module.upload.upload_el_wh_4')}}</span>
				{{ maxSizeMB }}M/
				<span>{{$t('module.upload.upload_el_wh_5')}}</span>；
				<!-- <br />
        2、请选取完文件后点击上传到服务器； -->
			</div>

			<!-- 自定义显示列表 -->
			<div v-if="!showFileList">
				<ul class="file-list-customize">
					<li v-for="(file,index) in fileList" :key="index">
						<!-- <el-image :src="item.url"></el-image> -->
						<!-- <el-image style="width: 100px; height: 100px" :src="item.url"
							:preview-src-list="fileList.map(itemPre=>{ return itemPre.url})">
						</el-image> -->
						<el-image style="width: 80px; height: 80px" :z-index="9999" :fit="'contain'" :src="file.url"
							:preview-src-list="fileList.map(itemPre=>{ return itemPre.url})">
							<div slot="error" class="image-slot">
								<i class="el-icon-document"></i>
								<!-- {{file.name}} -->
							</div>
						</el-image>

						<!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt=""> -->
						<!-- <span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
								<i class="el-icon-download"></i>
							</span>
							<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
								<i class="el-icon-delete"></i>
							</span>
						</span> -->
						
						<!-- 0
						: 
						{code: "10", codeText: "快递 Label", codeTextEn: "Express Label", createBy: "1",…}
						1
						: 
						{code: "11", codeText: "SKU标签", codeTextEn: "SKU Label", createBy: "1",…}
						2
						: 
						{code: "15", codeText: "箱唛", codeTextEn: "Mark No.", createBy: "1", createTime: "2024-03-19 14:08:17",…}
						3
						: 
						{code: "20", codeText: "BOL", codeTextEn: "BOL", createBy: "1", createTime: "2021-07-27 17:16:54",…}
						4
						: 
						{code: "30", codeText: "POD", codeTextEn: "POD", createBy: "1", createTime: "2021-07-27 17:17:07",…}
						5
						: 
						{code: "90", codeText: "其他附件", -->
						
						<span v-if="file.fileTagData">
							<el-tag :effect="'plain'" type="warning" v-if="'10'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'plain'" type="success" v-else-if="'11'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'light'" type="warning" v-else-if="'15'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'plain'" type="danger" v-else-if="'20'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'light'" type="primary" v-else-if="'30'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'plain'" type="primary" v-else-if="'90'==file.fileTagData.type">
								{{file.fileTagData.typeName}}
							</el-tag>
							<el-tag  :effect="'plain'" type="info" v-else>
								{{file.fileTagData.typeName}}
							</el-tag>
						</span>
						

						<span class="file-c-name">{{file.name}}</span>

						<!-- <el-link class="" @click="handlePictureCardPreview(file)">
							<i class="el-icon-zoom-in"></i>放大
						</el-link> -->

						<!-- <el-link class="" @click="handleDownload(file)">
							<i class="el-icon-download"></i>{{$t('i18nn_245883c80f181c4a')}}</el-link> -->

						<el-link type="warning" class="" @click="handleRemove(file,index)">
							<i class="el-icon-delete"></i>{{$t('i18nn_e33c9b93c36fd250')}}</el-link>

					</li>
				</ul>
			</div>

		</el-upload>
		<el-progress v-show="showProgress" :text-inside="true" :stroke-width="15" :percentage="progress"></el-progress>
	</div>
</template>
<script>
	//   let oss = require('ali-oss');
	// let client= new OSS.Wrapper({
	//   accessKeyId: 'your access key',
	//   accessKeySecret: 'your access secret',
	//   bucket: 'your bucket name',
	//   region: 'oss-cn-hangzhou'
	// });

	// let result = await client.multipartUpload('object-key', 'local-file', {
	//     progress: async function (p) {
	//       console.log('Progress: ' + p);
	//     }
	//   });

	// import UploadFileApi from '@/api/system/UploadFileApi'
	// import * as util from 'utils/common'
	// import OSS from 'ali-oss';
	import {
		ossConfig,
		ossClient
	} from '@/config/ossConfig.js';

	export default {
		name: 'fileUpload',
		props: {
			auto_upload: {
				type: Boolean,
				default: function() {
					return false;
				}
			},
			data_extra: {
				type: Object
			},
			accept: {
				type: String,
				default: function() {
					return '';
				},
				required: true
			},
			limit: {
				type: Number,
				default: function() {
					return 5;
				}
			},
			listType: {
				type: String,
				default: 'picture'
			},
			fileId: {
				type: String,
				required: true
			},
			// fileKey: {
			// 	type: String,
			// 	required: true
			// },
			// bucket: {
			// 	type: String,
			// 	required: true
			// },
			fileListShow: {
				type: Array
			},
			showFileList: {
				type: Boolean,
				default: function() {
					return true;
				}
			},
			fileTagData:{
				type: Object,
				default: function() {
					return {
						type:'90',
						typeName:'其他附件',
					}
				}
			},
			root_folder: {
				type: String,
				default: function() {
					return 'storage2/';
				}
			},
			folder: {
				type: String,
				default: function() {
					return '';
				}
			},
			btnText: {
				type: String,
				default: function() {
					return ''
				}
			},
			//每个文件限定的大小
			maxSizeMB: {
				type: Number,
				default: function() {
					return 5;
				}
				// required: true
			},
			watchKey: ''
		},
		watch: {
			watchKey: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		data() {
			return {
				uploadUrl: '',
				showProgress: false,
				progress: 0,
				// uploadFileLength: 0,
				// uploadFileSuccess: 0,
				// uploadFileNames: [],
				// uploadFileName: [],
				// limit: 5,
				uploadHeaders: {
					authorization: ''
				},
				fileList: [],
				// fileListChange: [],
				imageUrlList: [],
				// imageUrl:"",
				loading: false
			};
		},

		methods: {
			initData() {
				console.log('this.fileListShow', this.fileListShow);
				if (this.fileListShow.length <= 0) {
					this.fileList = [];
					// this.imageUrlList = [];
				} else {
					try {
						this.fileList = JSON.parse(JSON.stringify(this.fileListShow));
					} catch (err) {
						console.log(err);
						this.fileList = this.fileListShow.concat([]);
					}

					// this.imageUrlList = this.fileList.concat([]);
				}

				this.loading = false;
				this.showProgress = false;
				this.progress = 0;

				console.log('this.loading', this.loading);
			},
			clearFileList() {
				//清空图片
				try {
					// console.log(this.$refs.upload);
					this.$refs.upload.clearFiles();
				} catch (err) {
					console.log(err);
				}
				// this.fileList = [];
			},

			/**
			 * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
			 * @author
			 * @param    {object}   option [上传选项]
			 * @return   {null}   [没有返回]
			 */
			async fnUploadRequest(option) {
				console.log('fnUploadRequest', option);
				try {
					let vm = this;
					vm.loading = true;
					this.showProgress = false;
					this.progress = 0;
					// 获取OSS配置信息
					// let uploadFileApi = new UploadFileApi()
					// let ret = await uploadFileApi.fileOssParams()
					// if (!(ret.data && ret.data.code === '0' && ret.data.data)) {
					//   throw new Error('获取OSS参数失败')
					// }
					// let ossData = JSON.parse(ret.data.data)
					// let relativePath = ossData.relativePath
					// let client = new OSS.Wrapper({
					//   policy: ossData.policy,
					//   accessKeyId: ossData.accessid,
					//   accessKeySecret: ossData.accesssecret,
					//   bucket: ossData.bucket,
					//   signature: ossData.signature
					// })
					//
					//
					let file = option.file;
					console.log('option.file', option.file);
					//
					// let bucket = this.bucket;
					let relativePath = '';
					// let relativePathArr = [this.folder, this.fileKey, this.fileId, new Date().getTime(), file.name.substr(file.name.lastIndexOf('.')).toLowerCase()];
					//替换影响链接的字符
					let fileName = file.name ? file.name.replace(/(\+|\%|\#|\&|\\|\/|\=|\?|\:|\s)/g, "_") : '';
					// let ymd = this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime());
					let relativePathArr = [ '_'+this.fileId, this.$Utils.fomatterDate_YYYYMMdd2(new Date().getTime()), new Date().getTime()+"_"];
					relativePath = this.root_folder + this.folder + relativePathArr.join('/_') + fileName;
					// relativePath = this.folder+relativePath;
					// let client= new OSS.Wrapper({
					//   accessKeyId: 'your access key',
					//   accessKeySecret: 'your access secret',
					//   bucket: 'your bucket name',
					//   region: 'oss-cn-hangzhou'
					// });
					// var client = new OSS({
					// 	region: 'oss-cn-hongkong',
					// 	accessKeyId: 'LTAI5t9pUsVQBnwyGgeGGuc4',
					// 	accessKeySecret: 'XljA667qnWvCmtwchv0mfyQPevtUsw',
					// 	bucket: bucket,
					// 	endpoint: 'oss-cn-hongkong.aliyuncs.com',
					// 	secure: true //https
					// });
					let client = ossClient();

					// const point = file.name.lastIndexOf('.');
					// let suffix = file.name.substr(point);
					// let fileName = file.name.substr(0, point);
					// let date = vm.$moment().format('YYYYMMDDHHmmss')
					// let date = new Date().getTime();
					// let fileNames = `${date}_${fileName}_${suffix}`;
					// let fileNames = "";
					// 分片上传文件
					let ret = await client.multipartUpload(relativePath, file, {
						progress: async p => {
							let e = {};
							e.percent = p * 100;
							option.onProgress(e);
							this.showProgress = true;
							this.progress = Math.floor(p * 100);
							if (100 == this.progress) {
								vm.loading = false;
							}
						}
					});
					console.log(ret);
					if (ret.res.statusCode === 200) {
						console.log('onSuccess');
						//调用此处，执行两次
						// option.onSuccess(ret)
						return ret;
					} else {
						vm.loading = false;
						option.onError(this.$t('module.upload.fail_upload'));
					}
				} catch (error) {
					console.error(error);
					this.loading = false;
					option.onError(this.$t('module.upload.fail_upload') + 'code:1');
					this.$error(error.message);
				}
			},
			//上传
			submitUpload() {
				console.log('submitUpload', this.fileList);
				if (this.fileList.length > 0) { //如果有待上传文件
					// return;
					this.$refs.upload.submit();
				} else { //没有待上传文件，回调空数组
					this.$emit('UploadSuccessUrl', []);
				}

			},
			//文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
			//多个文件会多次触发
			fnOnChange(file, fileList) {
				console.log('fnOnChange', file, fileList);
				// this.disabled = false;
				// let tempFileList = [];
				let delFilesUIDS = []; //待过滤的文件

				if (file) {
					//校验格式
					if ('all' == this.accept || '' == this.accept) {
						//不校验格式
						// tempFileList.push(file)
					} else if (this.accept && this.accept.indexOf(file.raw.type) > -1) { //类型匹配
						// this.$message.warning(this.$t('module.upload.Please_upload_format'));
						// return false;
					} else { //类型不匹配
						// this.fileList = [];
						// this.fileListChange = [];
						// tempFileList = fileList.map(item=>{//排除该
						// 	return item.uid
						// })
						delFilesUIDS.push(file.uid);
						this.$message.error(this.$t('module.upload.Please_upload_format'));
						// return;
					}
					//校验文件大小
					if (file.size < 1024 * 1024 * this.maxSizeMB) {
						// this.upLoadAction();
					} else {
						// this.fileList = [];
						// this.fileListChange = [];
						delFilesUIDS.push(file.uid);
						this.$message.error(this.$t('module.upload.uploaded_over') + this.maxSizeMB + 'M');
						// return;
					}
				} else {
					// this.fileList = [];
					// this.fileListChange = [];
					console.log('未知文件，重复上传时出现');
					// delFilesUIDS.push(file.uid);
					this.$message.error(this.$t('i18nn_d9d6266f40f67534'));
					// return;
				}
				
				
				// let delFilesUIDS = delFiles.map(item=> {return item.uid});
				// 过滤重复
				let cur_fileList = fileList.filter(item => {
					return !delFilesUIDS.includes(item.uid);
				});
				// 打标签tag
				if(this.fileTagData && this.fileTagData.type){
					cur_fileList = fileList.map(item=>{
						if(item.fileTagData && item.fileTagData.type){
							
						} else {
							item.fileTagData = this.fileTagData;
						}
						return item;
					});
				}
				
				this.fileList = cur_fileList;
				
				// let fileTagData
				// this.fileList = this.fileListChange;

				console.log('fnOnChange end', this.fileList);

				//多个文件，选会触发多次，以最后一次为待上传最终文件
				// this.$emit('fileChange', fileList);
				//多个文件，选会触发多次，以最后一次为待上传最终文件
				this.$emit('fileChange', this.fileList);
			},
			/**
			 * @description [fnUploadSuccess 文件上传成功的函数]
			 * @author
			 * @return   {null}  [没有返回]
			 */
			async fnUploadSuccess(response, file, fileList) {
				// TODO
				console.log('fnUploadSuccess', response, file, fileList);
				console.log('fileList', fileList.length);
				// console.log('this.fileList',this.fileList);
				// let imageUrl = "https://"+response.bucket+".oss-cn-shanghai.aliyuncs.com/"+response.name;
				let imageUrlList = [];
				fileList.forEach((item, index, arr) => {
					// item为arr的元素，index为下标，arr原数组
					console.log('fileList item', item);
					let imageUrl = '';
					if (item.response) {
						//仅新增，不读取原记录
						// imageUrl = 'https://' + item.response.bucket + '.oss-cn-hongkong.aliyuncs.com/' + item
						// 	.response.name;

						// imageUrl = `https://${item.response.bucket}.${ossConfig.endpoint}/${item.response.name}`;
						imageUrl = `${ossConfig.domainName}/${item.response.name}`;
						// let name = item.name;
						let imageObj = {
							name: item.name,
							url: imageUrl,
							uid: item.uid,
							fileTagData: item.fileTagData
						};
						imageUrlList.push(imageObj);
					}
					//  else {
					//   imageUrl = item.url;
					// }
					// if (imageUrl.indexOf('blob:http') <= -1) {

					// }
				});
				// fileList.forEach((item, index, arr) => { // item为arr的元素，index为下标，arr原数组
				// console.log(item);
				// let imageUrl = "https://" + response.bucket + ".oss-cn-shanghai.aliyuncs.com/" + response.name;
				// // console.log('indexOf', this.imageUrlList.join(",").indexOf(imageUrl));
				// if (this.imageUrlList.join(",").indexOf(imageUrl) < 0) {
				//   this.imageUrlList.push(imageUrl);
				// }

				// });
				// imageUrlList.push({});
				// this.imageUrl = imageUrl;
				console.log('imageUrlList', imageUrlList);
				console.log('imageUrlList length', imageUrlList.length);
				imageUrlList = this.uniq(imageUrlList.concat([]));
				console.log('fileList', fileList.length);
				// 文件个数和返回url个数相等时，多图片会重复调用成功方法
				if (imageUrlList.length === fileList.length) {
					//全部上传成功回调
					// console.log('imageUrlList',imageUrlList);
					this.$emit('UploadSuccessUrl', imageUrlList);
					//清空待上传文件
					// this.fileListChange = [];
				}
			},
			/*
			 * 删除重复
			 *
			 * 方法的实现代码相当酷炫，
			 * 实现思路：获取没重复的最右一值放入新数组。
			 * （检测到有重复值时终止当前循环同时进入顶层循环的下一轮判断）*/
			uniq(array) {
				var temp = [];
				var index = [];
				var l = array.length;
				for (var i = 0; i < l; i++) {
					for (var j = i + 1; j < l; j++) {
						if (array[i].url === array[j].url) {
							i++;
							j = i;
						}
					}
					temp.push(array[i]);
					index.push(i);
				}
				// console.log(index);
				return temp;
			},
			//上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
			//多个文件，多次触发
			fnBeforeUpload(file) {
				console.log('fnBeforeUpload', file);
				// if (file.size < 2097152) {
				//   // this.upLoadAction();
				//   console.log('小于2M');
				// } else {
				//   // this.loading = false;
				//   this.$message.warning('图片尺寸不能大于 2M');
				//   return false;
				// }
				if (file) {
					console.log(this.accept, file.type);
					if ('all' == this.accept || '' == this.accept) {
						//不校验格式
					} else if (this.accept && this.accept.indexOf(file.type) > -1) {
						if (file.size < 1024 * 1024 * this.maxSizeMB) {
							// this.upLoadAction();
						} else {
							this.$message.warning(this.$t('module.upload.uploaded_over') + this.maxSizeMB + 'M');
							return false;
						}
					} else {
						this.$message.warning(this.$t('module.upload.Please_upload_format'));
						return false;
					}
				} else {
					console.log('未知文件，重复上传时出现');
					return;
				}
				// this.$message.warning(this.$t('i18nn_b180cd998ccbe52e'));
			},
			//文件超出个数限制时的钩子
			fnUploadExceed(files, fileList) {
				console.log('fnUploadExceed', files, fileList);
				this.$message.warning(this.$t('module.upload.files_exceeded'));
			},
			//文件删除
			fnOnRemove(file, fileList) {
				console.log('fnOnRemove', file, fileList);
				this.fileList = fileList;
				// this.fileList = this.fileListShow.concat([]);
				// this.imageUrlList = this.fileList.concat([]);
				// console.log('fnUploadExceed', files, fileList);
				// this.$message.warning(this.$t('i18nn_b180cd998ccbe52e'));
				this.$emit('fileChange', this.fileList);
			},
			//自定义删除
			handleRemove(file, index) {
				console.log('handleRemove',file);
				this.fileList.splice(index, 1);
				this.$emit('fileChange', this.fileList);
			},
		}
	};
</script>
<style scoped lang="less">
	// .upload-demo {
	//   width: 500px;
	// }
	.el-upload-list__item .is-success {
		.el-upload-list__item-status-label {
			display: none;
		}
	}

	
</style>